import React, { useEffect } from 'react';
import anime from 'animejs';

const IconVacancies = ({ shouldStart = false, id = '' }) => {
    const RunAnimation = () => {
        anime({
            targets: `.${id}handle`,
            strokeDashoffset: [anime.setDashoffset, 108],
            easing: 'easeInOutSine',
            duration: 1000,
            delay: function (el, i) {
                return i * 250;
            },
            direction: 'forward',
            loop: false,
        });
    };

    useEffect(() => {
        if (shouldStart) {
            RunAnimation();
        }
    });
    return (
        <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className={id + 'handle'}
                d="M34.2133 13.7576V10.8788C34.2133 10.1153 33.8251 9.38305 33.134 8.84318C32.443 8.3033 31.5057 8 30.5285 8H19.4739C18.4966 8 17.5594 8.3033 16.8683 8.84318C16.1773 9.38305 15.7891 10.1153 15.7891 10.8788V13.7576"
                stroke="#FDC201"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M44 24.9363C38.2254 28.276 31.6708 30.0307 25 30.0227C18.3279 30.0401 11.771 28.2848 6 24.9363"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.2422 24.1212H30.7573"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M42.4167 14.1894H7.58333C6.70888 14.1894 6 14.8983 6 15.7727V41.1061C6 41.9805 6.70888 42.6894 7.58333 42.6894H42.4167C43.2911 42.6894 44 41.9805 44 41.1061V15.7727C44 14.8983 43.2911 14.1894 42.4167 14.1894Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

IconVacancies.propTypes = {};

export default IconVacancies;
